.searchResultContainer {
    width: 70vw;
}

.searchResult {
    width: 55vw;
    height: 20vh;

    margin: 20px;
    padding: 20px;

    text-align: left;

    background-color: var(--bg-secondary);

    word-wrap: break-word;

    border-radius: 10px;

    transition: 0.3s ease;
}

.searchResult p {
    overflow: hidden;
}

.searchResult:hover {
    transform: scale(1.05);
}