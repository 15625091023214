:root {
    --bg-main: #222;
    --bg-secondary: #454545;
    --text-main: #dedede;
    --text-secondary: #898989;
    --sml: #4695D6;
    --sml-transparent: rgba(70,149,214,0.3)
}

body {
   overflow-x: hidden;

    background-color: var(--bg-main);
    color: var(--text-main);
}

/* Tiny lil margin for headings so that they look nice :) */
h1, h2, h3, h4, h5, p {
    margin: 1.5vh 1vw;
}

h1 {
    font-size: max(3vw,4vh);
}

h2 {
    font-size: max(2vw,3vh);
    transition: padding 1s;
}

h3 {
    font-size: max(1.3vw,2.2vh);
}

p {
    font-size: max(1vw,1.8vh);
    margin: 0;
}

i {
    color: #4695D6;
    font-style: normal;
}

a {
    color: var(--text-secondary);
    text-decoration: none;

    pointer-events: all;

    transition: 0.2s ease;
}

a:hover {
    cursor: pointer;

    color: var(--sml);
}

button {
    margin: min(0.2vh,0.3vw) max(0.2vw,0.3vh);
    padding: 1.3vh max(0.8vw,1vh);

    font-size: max(1.8vw,2vh);
    color: var(--text-main);

    background-color: var(--bg-secondary);

    border: none;
    border-radius: max(1vw,1vh);

    transition: 0.3s ease;
  }

  button:hover {
    cursor: pointer;

    transform: scale(1.1,1.1);
  }

  input {
    /*width: max(12vw,25vh);
    height: min(3vh,10vw);*/

    margin: 1vh 1vw;
    padding: 0.6vh 0.4vw;

    text-align: center;

    font-size: 1.6vmax;

    background-color: #eee;

    border: none;
    border-radius: 1vw;

    transition: 0.2s ease;
  }

  input:focus {
    background-color: #ddd;

    box-shadow:0 0 1.5vmin var(--sml);

    outline: none;
  }

/* USEFUL CLASSES */
.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.centerAlign {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
}

.evenSpacing {
    justify-content: space-around;
}

.container {
    width: 100vw;
    min-height: 100vh;
    
    overflow-y: hidden;

    align-items: stretch;
}

.formGroup {
    margin: 1vh 1vw;
}

.resetMargin {
    margin: 0;
}

.logo i {
    color: var(--sml);
}

@keyframes loadingDotAnim {
    0% {
        transform: translateY(-2vh);
    }
    50% {
        transform: translateY(2vh);
    }
    100% {
        transform: translateY(-2vh);
    }
  }

.loadingDots {
    margin: 3vh;
    height: 50vh;
}

.loadingDots .dot {
    width: 3vh;
    height: 3vh;

    margin: 1vh 1vh;

    border-radius: 100%;

    background-color: var(--text-main);
    animation: loadingDotAnim 2s infinite ease-in-out;
}


/* Main page */
.title {
    position: absolute;
    top: 40vh;

    transition: all 0.8s ease;
}

.title.top {
    top: 2vh;
}

.title h1 {
    margin: 0;
}

.title a {
    color: var(--text-main);
}

.title a:hover {
    filter: brightness(0.65);
}

.pageContent {
    margin-top: 27vh;
    margin-bottom: 5vh;
    min-height: 60vh;
}

.footer {
    position: relative;

    color: #888;

    margin-bottom: 3vh;
}

