.problemInfo {
    width: 80vw;
    text-align: left;
}

.problemInfo p {
    font-size: max(1.15vw, 1.8vh);
}

.problemInfo .note {
    margin-top: 5vh !important;
    font-weight: bold;
}

.problemInfo img {
    width: 40vw;

    margin: 5vh 0vw;
}

.problemInfo .desc {
    display: flex;
    flex-direction: row;

    max-width: 70vw;
}

.problemInfo .desc p {
    max-width: 70vw;

    margin: 2vh 0vw;
    
    word-wrap: break-word;
}

.problemInfo .desc a:hover {
    color: #888;
}

.solution {
    width: 100%;
    text-align: left;
}

.solution h2,h3 {
    margin: 0;
}

.solutionRow {
    margin: 1vh 0vw;
}

.solutionRow .solutionTitle {
    padding: 0.3vh 0.1vw;

    align-items: center;

    text-align: left;

    user-select: none;
    cursor: pointer;
}

.solutionRow .solutionTitle h2 {
    margin-right: 0.4vw;

    font-size: max(1.5vw, 2vh);
}

.solutionRow .dropdown {
    padding: 0.8vh 0.2vw;
    margin-bottom: 0.6vh;

    overflow: hidden;

    transition: height 0.4s ease;
}

.solutionRow .content p {
    margin-left: 0.3vw;
    padding: 1vh 0vw;

    font-size: max(1.3vw, 1.8vh);
    text-align: left;
}