.treeContainer {
    position: relative;

    width: 80vw;

    overflow: hidden;
}

.lineContainer {
    position: relative;
}

.lineContainer svg {
    transform: translate(0vw,2vh);
}

.nodeContainer {
    position: absolute;
    top: -2vh;
    left: 37.25vw; /* TODO: Figure out *why* it has to be this way because jesus */
}



.treeNode {
    position: absolute;
    top: 0;
    left: 0vw;

    transform: translate(var(--pos));
}

a {
    color: var(--text-main)
}

a:hover {
    color: var(--text-main)
}

.treeNode .content {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 5vw;
    height: 5vw;

    font-size: max(1vw, 1vh);

    border-radius: 50%;

    transition: 0.2s ease-out;
}

.disabled {
    background-color: var(--bg-secondary);

    box-shadow: 0.2vw 0.2vw var(--text-main);
}

.active {
    background-color: var(--sml);

    box-shadow: 0.2vw 0.2vw var(--bg-secondary);
}

.solved {
    background-color: #00ff99;
    color: #333;
    box-shadow: 0.2vw 0.2vw var(--text-main);
}

.active:hover {
    transform: translate(0.2vw,0.2vw);

    box-shadow: 0vw 0vw var(--bg-secondary);

    cursor: pointer;
}

/* PROBLEM MODAL */
.problemModal {
    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    min-height: 100dvh;

    padding-bottom: 10vh;

    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.problemModal .bg {
    position: fixed;

    width: 100%;
    height: 100%;

    background-color: rgba(0,0,0,0.7);
}

.problemModal .modal {
    position: absolute;
    top: 10vh;
    left: 20vw;

    width: 60vw;
    min-height: 60vh;

    padding: 5vw 3vh;

    color: rgb(68, 68, 68);

    background-color: #ddd;
    
    border-radius: 1.5vw;
}

.problemModal .modal::after { /* jesus this is atrocious */
    position: absolute;
    content: "";
    bottom: -10vh;
    height: 10vh;
    width: 1px;
}

.problemModal .modal .close {
    position: absolute;
    top: 1vh;
    left: 1vw;
    color: black;
}

.problemModal .content {
    padding: 3vh 5vw;
}

.problemModal .content * {
    margin: 1.2vh 1vw;
}

.problemModal .content .problemDesc {
    margin: 2.5vh 0vw;

    font-size: max(1.1vw,1.2vh);
}

.problemModal .content input {
    border: #333 solid 0.1vw;

    color: black;
}

.problemModal .content .submitBtn {
    position: absolute;
    bottom: 5vh;
}
